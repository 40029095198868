import Image from "next/image";
import { SeasonPlayer } from "../../models/Player";
import { SeasonTeam } from "../../models/Team";
import { News } from "../../models/types";
import PlaceholderPlayer from "../../assets/player_dummy.png";
import { Venue } from "../../models/Venue";

interface TeamImageProps {
  team?: SeasonTeam;
  alt?: string;
  size?: "sm" | "lg" | "md";
  test?: boolean;
  className?: string;
}
export function TeamImage({
  team,
  alt,
  size = "sm",
  test = false,
  className,
}: TeamImageProps) {
  if (test)
    return (
      <div
        className={`flex-shrink-0 ${
          size === "sm"
            ? "w-[24px] h-[24px]"
            : size === "lg"
            ? "w-[50px] h-[50px]"
            : "w-[36px] h-[36px]"
        }`}
      >
        <Image
          width={100}
          height={100}
          alt={alt ?? "Team Image"}
          className="w-full h-full object-contain flex-shrink-0"
          src="https://upload.wikimedia.org/wikipedia/commons/9/9e/Logo_Mainz_05.svg"
        />
      </div>
    );

  return (
    <div
      className={`
        ${size === "sm" ? "w-[24px] h-[24px]" : "w-[50px] h-[50px]"}  
        ${className || ""}
      `}
    >
      <Image
        width={1000}
        height={1000}
        alt={alt ?? "Team Image"}
        title={team?.name}
        className={`w-full h-full object-contain`}
        src={
          team
            ? team.logoPngUrl === "error"
              ? "/assets/placeholder/team_dummy.svg"
              : team.logoPngUrl || "/assets/placeholder/team_dummy.svg"
            : "/assets/placeholder/team_dummy.svg"
        }
      />
    </div>
  );
}

interface PlayerImageProps {
  player?: SeasonPlayer;
  alt?: string;
  size?: "sm" | "lg" | "xl" | "full";
  className?: string;
}
export function PlayerImage({
  player,
  alt,
  size = "lg",
  className,
}: PlayerImageProps) {
  return (
    <div
      className={`${
        size === "sm"
          ? "w-[30px] h-[30px]"
          : size === "lg"
          ? "w-[60px] h-[60px]"
          : size === "xl"
          ? "w-[100px] h-[100px]"
          : "w-auto lg:h-[250px]"
      } ${className ?? ""}`}
    >
      <Image
        alt={alt ?? "Player Image"}
        title={`${player?.firstName} ${player?.lastName}`}
        height={800}
        width={800}
        src={
          player
            ? player.imageUrl === "error"
              ? PlaceholderPlayer
              : player.imageUrl || PlaceholderPlayer
            : PlaceholderPlayer
        }
        className="w-full h-full object-fit object-contain"
      />
    </div>
  );
}

interface NewsImageProps {
  news: News;
  type: "big" | "small";
}
export function NewsImage({ news, type }: NewsImageProps) {
  return (
    <Image
      src={news.feature_image ?? "/news_dummy.png"}
      alt={news.title}
      fill
      className={`newsteaser_img h-auto ${
        type == "big" ? "rounded-md" : "max-lg:rounded-md"
      }`}
    />
  );
}

interface VenueImageProps {
  venue: Venue;
  alt?: string;
}
export function VenueImage({ venue, alt }: VenueImageProps) {
  return (
    <Image
      width={1400}
      height={1400}
      alt={alt ?? "Venue Image"}
      src={
        venue.imageUrl === "error"
          ? "/news_dummy.png"
          : venue.imageUrl || "/news_dummy.png"
      }
      className="w-full h-auto rounded-md"
    />
  );
}

export function TeamTeaserImage({ team, className, alt }: TeamImageProps) {
  return (
    <div
      className={`
        w-full h-auto
        ${className || ""}
      `}
    >
      <Image
        width={1000}
        height={1000}
        alt={alt ?? "Team Image"}
        className={`w-full h-full object-contain`}
        src={
          team
            ? team.teaserImageUrl === "error"
              ? "/assets/placeholder/team_dummy.svg"
              : team.teaserImageUrl || "/assets/placeholder/team_dummy.svg"
            : "/assets/placeholder/team_dummy.svg"
        }
      />
    </div>
  );
}
export function TeamDetailImage({ team, className, alt }: TeamImageProps) {
  return (
    <div
      className={`
        w-full h-auto
        ${className || ""}
      `}
    >
      <Image
        width={1000}
        height={1000}
        alt={alt ?? "Team Image"}
        className={`w-full h-full object-contain`}
        src={
          team
            ? team.teamImageUrl === "error"
              ? "/assets/placeholder/team_dummy.svg"
              : team.teamImageUrl || "/assets/placeholder/team_dummy.svg"
            : "/assets/placeholder/team_dummy.svg"
        }
      />
    </div>
  );
}
export function PlayerTeaserImage({ player, alt }: PlayerImageProps) {
  return (
    <div className={`w-auto h-full`}>
      <Image
        width={1000}
        height={1000}
        alt={alt ?? "Player Image"}
        className={`w-full h-full object-contain`}
        src={
          player
            ? player.actionImageUrl === "error"
              ? PlaceholderPlayer
              : player.actionImageUrl || PlaceholderPlayer
            : PlaceholderPlayer
        }
      />
    </div>
  );
}
